<template>
  <div class="event">
    <div class="map">
      <img :src="location.image" />
    </div>
    <div class="event-body text-left">
      <h3>{{ title }}</h3>
      <ul class="list-unstyled">
        <li>
          <i class="material-icons">event</i>
          <span>{{ $moment(start).format("dddd, DD MMMM YYYY") }}</span>
        </li>
        <li>
          <i class="material-icons">schedule</i>
          <span
            >{{ $moment(start).format("HH:mm") }}
            {{ end ? " - " + end : "" }} WIT</span
          >
        </li>
        <li>
          <i class="material-icons">place</i>
          <span v-html="location.address"></span>
        </li>
      </ul>

      <a
        :href="location.direction"
        class="d-flex align-items-center justify-content-center text-uppercase py-2 btn btn-primary"
      >
        <i class="material-icons mr-2">follow_the_signs</i>
        Tunjukan Arah
      </a>
    </div>
  </div>
</template>

<script>
// import AGWMap from "@/components/Maps";

export default {
  components: {
    // AGWMap
  },
  props: {
    location: Object,
    title: String,
    start: String,
    end: String
  }
};
</script>

<style scoped lang="scss">
.event {
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  margin-bottom: 2rem;
  border-radius: 8px;
  overflow: hidden;

  .btn {
    letter-spacing: 3px;
  }

  img {
    max-width: 100%;
  }
}

.event-body {
  padding: 1rem;

  h3 {
    font-size: 1.25rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
  }

  ul {
    margin-top: 1rem;

    li {
      display: flex;
      // align-items: center;
      margin-bottom: 0.75rem;
      font-size: 0.9rem;

      i {
        margin-right: 0.5rem;
        color: #444;
      }
    }
  }
}
</style>
