<template>
  <section id="pawiwahan">
    <div class="container">
      <div class="heading text-center with-border" data-aos="fade-down">
        <h2 class="title text-uppercase text-teal-dark">Wedding</h2>
        <div class="sub-title">We Are Getting Married</div>
      </div>

      <p
        class="font-weight-lighter text-center font-italic mar-bottom-25"
        data-aos="fade-right"
        data-aos-delay="300"
      >
        Tuhan membuat segala sesuatu indah pada waktunya,
        <br />indah sat Dia mempertemukan, <br />indah sat Dia menumbuhkan kasih
        <br />dan indah sat Dia mempersatukan putra-putri kami dalam
        <br />ikatan pernikahan kudus.
      </p>

      <div class="row justify-content-center pawiwahan pad-top-30">
        <div
          class="col-md-6 col-lg-5 mar-bottom-30"
          data-aos="zoom-in-right"
          data-aos-delay="150"
        >
          <div class="picture">
            <img :src="$parent.wedding.man.photo" />
          </div>
          <div class="name">{{ $parent.wedding.man.name }}</div>
          <p class="text-muted mb-0">
            {{ $parent.wedding.man.description }} dari pasangan
          </p>
          <p class="font-weight-bold mb-0">{{ $parent.wedding.man.parents }}</p>
          <p class="font-weight-lighter mb-0">
            {{ $parent.wedding.man.address }}
          </p>
        </div>
        <div class="d-md-none col-12">
          <svg
            id="divider"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 272.91 29.75"
          >
            <defs></defs>
            <title>divider</title>
            <g id="Layer_2" data-name="Layer 2">
              <g id="OBJECTS">
                <path
                  class="cls-1"
                  d="M119.23,0s-23,1.59-13.08,18.18,22,8.29,11.49,0-23-8.95-31.24,4.68c0,0-9.05-15.83-23.73-10.43S21.86,30.31,0,22.36c11.64,12.5,59.48-7.49,67.07-8.48,6.16-.81,15.73,7.63,19.33,15.87.2-7.39,17.35-21.3,30.07-8.81S93.92,18.68,107.33,6C112.15,1.49,119.23,0,119.23,0Z"
                />
                <path
                  class="cls-1"
                  d="M153.68,0s23,1.59,13.08,18.18-22,8.29-11.48,0,23-8.95,31.23,4.68c0,0,9.05-15.83,23.73-10.43s40.81,17.88,62.67,9.93c-11.64,12.5-59.48-7.49-67.07-8.48-6.15-.81-15.73,7.63-19.33,15.87-.2-7.39-17.35-21.3-30.07-8.81S179,18.68,165.59,6C160.77,1.49,153.68,0,153.68,0Z"
                />
                <path
                  id="love"
                  class="cls-1"
                  d="M138.54,29.38S121.19,21,123.47,6.71c2.59-10.81,14.15-6.55,13.39,3.34,0,0,.31-9.14,8.53-8.52S154.83,17.51,138.54,29.38Z"
                />
              </g>
            </g>
          </svg>
        </div>
        <div
          class="col-md-6 col-lg-5 mar-bottom-30"
          data-aos="zoom-in-left"
          data-aos-delay="350"
        >
          <div class="picture right">
            <img :src="$parent.wedding.woman.photo" />
          </div>
          <div class="name">{{ $parent.wedding.woman.name }}</div>
          <p class="text-muted mb-0">
            {{ $parent.wedding.woman.description }} dari pasangan
          </p>
          <p class="font-weight-bold mb-0">
            {{ $parent.wedding.woman.parents }}
          </p>
          <p class="font-weight-lighter mb-0">
            {{ $parent.wedding.woman.address }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import GetOffsite from "@/libs/offset";

export default {
  computed: {
    y() {
      return this.$root.scroll.Y;
    }
  },
  watch: {
    y() {
      const height = window.innerHeight;

      this.$el.querySelectorAll(".picture").forEach(item => {
        const itemOffset = GetOffsite(item);
        const center = itemOffset.top - height / 2 + item.offsetHeight / 2;
        const pos = center - this.y;
        const offset = item.offsetHeight;

        if (pos < offset && pos > offset * -1) {
          let point = pos / offset;
          let positivePoint = point < 0 ? point * -1 : point;
          let scale = 1 + 0.1 - positivePoint / 10;
          let rotate = (1 - positivePoint) * -1;

          item.style.transform = `scale(${scale}) rotate(${rotate}deg)`;
        }
      });
    }
  }
};
</script>
