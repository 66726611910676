<template>
  <section class="bg-blue-light pad-y-60 text-center" style="overflow: hidden; position: relative;">
    <img src="https://ik.imagekit.io/aryraditya/ceremonyku/corner_2x_sZXnEWtgp.png?tr=w-500" style="position: absolute; top: 0px; right: 0px; width: 55vw; max-width: 400px; transform: translateY(-20%) translateX(20%); opacity: 0.5;"></img>
    <div class="container" style="position: relative">
      <div class="heading pt-5" data-aos="fade-down">
      <h2 class="title font-secondary fs-40 text-teal-dark">Event</h2>
      </div>

      <p
        class="font-weight-lighter font-italic mar-bottom-60"
        data-aos="fade-right"
        data-aos-delay="300"
      >
        Atas rencana-NYA yang indah kami bermaksud menyelenggarakan Acara
        Pernikahan putra-putri kami, yang akan diselenggarakan pada
      </p>

      <div class="row py-3">
          <div class="col-lg-6">
            <Event
              :location="{
                image: 'https://ik.imagekit.io/crk/brian-keren/1511.jpg?updatedAt=1664770695791&tr=w-778,h-390,fo-custom,cm-extract',
                map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7963.0593630564335!2d128.17489086977542!3d-3.6938384000000015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2d6ce85195bb785f%3A0xb9068c14f391943d!2sGereja%20Maranatha%20GPM!5e0!3m2!1sen!2sid!4v1664770150033!5m2!1sen!2sid',
                direction: 'https://www.google.com/maps/place/854M%2BFF6+Gereja+Maranatha+GPM,+Uritetu,+Sirimau,+Kota+Ambon,+Maluku/@-3.6932979,128.1892491,16z/data=!4m8!1m2!2m1!1sgereja+maranatha+ambon!3m4!1s0x2d6ce85195bb785f:0xb9068c14f391943d!8m2!3d-3.6938384!4d128.1836456',
                address: 'Di Gereja Maranatha <br />Jl. Pattimura, Uritetu, Kec. Sirimau, Kota Ambon, Maluku',
              }"
              start="2022-10-07 15:00"
              end=""
              title="Pemberkatan"
              data-aos="fade-right" data-aos-delay="300"
            />
          </div>
          <div class="col-lg-6">
            <Event 
              :location="{
                image: 'https://images.unsplash.com/photo-1511795409834-ef04bbd61622?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&h=500&q=80',
                map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3943.5811352577143!2d115.16993231478453!3d-8.731262993727734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0!2zOMKwNDMnNTIuNiJTIDExNcKwMTAnMTkuNiJF!5e0!3m2!1sen!2sid!4v1604537583070!5m2!1sen!2sid',
                direction: 'https://goo.gl/maps/z3ffRce2kKR59wYQA',
                address: 'Gedung Baileo Oikumene <br /> Jl. Pattimura, Uritetu, Kec. Sirimau, Kota Ambon, Maluku',
              }"
              start="2022-10-07 18:00"
              end="Selesai"
              title="Resepsi"
              data-aos="fade-left" data-aos-delay="300"
            />
          </div>
        </div>


      <p
        class="font-weight-lighter font-italic"
        data-aos="fade-left"
        data-aos-delay="600"
      >
        Merupakan suatu kehormatan dan kebahagiaan bagi kami apabila
        Bapak/Ibu/Saudara/i berkenan hadir dan memberikan doa restu kepada
        putra-putri kami. Atas kehadiran serta doa restu Bapak/Ibu/Saudara/i,
        kami sekeluarga mengucapkan terima kasih. Kami yang berbahagia.
      </p>

      <h3
        class="font-secondary text-teal-dark mb-0 mar-top-25"
        data-aos="fade-up"
        data-aos-delay="700"
      >
        {{ $parent.wedding.man.surname }} & {{ $parent.wedding.woman.surname }}
      </h3>
    </div>
  </section>
</template>

<script>
import Event from "./Event.vue";
export default { components: { Event } };
</script>
